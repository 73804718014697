<template>
  <v-card class="pa-2">
    <v-data-iterator item-key="IdCarteraCobro" :items="value" :search="search" :footer-props="{
					showCurrentPage: true,
				}">
      <template v-slot:header>
        <div class="d-flex align-center">
          <v-subheader>Clientes impagados por canal</v-subheader>
          <v-spacer />
          <v-text-field
            dense
            hide-details
            filled
            prepend-inner-icon="mdi-magnify"
            v-model="search"
          />
          <v-btn
            color="primary"
            icon
            class="ml-1"
            text
            @click.stop="$emit('exportar', value, 'clientesImpagadosPorCanal')"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
        <v-divider />
      </template>

      <template v-slot:default="{ items }">
        <v-list dense>
          <v-list-item v-for="item in items" :key="item.CanalAgente">
            <v-list-item-content>
              <div class="d-flex align-center">
                {{ item.CanalAgente }}
              </div>
            </v-list-item-content>
            <v-list-item-action class="d-flex align-center flex-row">
              <v-chip x-small color="secondary" outlined class="v-chip--active">
                {{ item.totalClientesImp.size }} / {{ item.totalClientes }}
              </v-chip>
              <span class="mx-1">-</span>
              <v-chip x-small color="secondary" outlined class="v-chip--active"
                >{{ item.porcentaje }} %</v-chip
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </v-data-iterator>
  </v-card>
</template>

<script>
export default {
  props: {
    value: { type: Array, optional: false },
  },
  data() {
    return {
      search: null,
    };
  },
};
</script>

<style scoped>
.v-data-iterator >>> .v-data-footer__select {
  display: none !important;
}
.v-data-iterator >>> .v-data-footer__pagination {
  margin-left: auto !important;
}
</style>